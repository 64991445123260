export const config = {
  apiUrl: "https://nvam.us", // production
  s3BucketUrl: "https://nvam.nyc3.cdn.digitaloceanspaces.com/",
  assetUrl: "https://nvam.nyc3.cdn.digitaloceanspaces.com/live/assets/",
  altImg: "images/no_avatar_profile.jpg",
  baseUrl: "https://nvam.us/",
  altInvImg: "images/inv_img.jpeg",
  mainUrl: "https://nvam.us", // NVAM url for application form
  pusherKey: "d9353a41d1335203fe9a",
  pusherCluster: "us3",
  dsn: "https://870d683c32034afdbb3d6d6ce9fddc16@o432627.ingest.sentry.io/5680197"
};

// export const config = {
//   apiUrl: "https://nvam.wrg-apps.com", // development
//   s3BucketUrl: "https://nvam.nyc3.cdn.digitaloceanspaces.com/",
//   assetUrl: "https://nvam-dev.nyc3.cdn.digitaloceanspaces.com/dev/assets/",
//   altImg: "images/no_avatar_profile.jpg",
//   baseUrl: "https://nvam.wrg-apps.com/",
//   altInvImg: "images/inv_img.jpeg",
//   mainUrl: "https://nvam.wrg-apps.com", // NVAM url for application form
//   pusherKey: "d9353a41d1335203fe9a",
//   pusherCluster: "us3",
//   dsn:"https://c054a843149a4bb78c8c63e54507fb38@o432627.ingest.sentry.io/568$
// };
  
// export const config = {
//     apiUrl: "https://nvam-stage.wrg-apps.com", // staging
//     s3BucketUrl: "https://nvam.nyc3.cdn.digitaloceanspaces.com/",
//     assetUrl: "https://nvam.nyc3.cdn.digitaloceanspaces.com/dev/assets/",
//     altImg: "images/no_avatar_profile.jpg",
//     baseUrl: "https://nvam-stage.wrg-apps.com/",
//     altInvImg: "images/inv_img.jpeg",
//     mainUrl: "https://nvam-stage.wrg-apps.com", // NVAM url for application form
//     pusherKey: "d9353a41d1335203fe9a",
//     pusherCluster: "us3",
//     dsn:"https://c054a843149a4bb78c8c63e54507fb38@o432627.ingest.sentry.io/5680194"
// };